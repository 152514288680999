import React from 'react';
import {
    MDBContainer,
    MDBMask,
    MDBView,
    MDBCol, MDBRow
} from 'mdbreact';

class ProjektCard extends React.Component{
    render(){
        return(
            <MDBCol size="6"  style={{paddingTop: "8.5%"}}>
                <MDBContainer className="px-0 py-0">
                    <a href={this.props.imgs}>
                    <MDBView hover>
                        <img src={this.props.img} class="img-fluid" alt="sample image" />
                        <MDBMask className="flex-center" overlay="stylish-strong">
                            <p className="white-text">{this.props.title}</p>
                        </MDBMask>
                    </MDBView>
                    </a>
                </MDBContainer>
            </MDBCol>
        );
    }
}

export class DoneProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
        return (
            <MDBMask>
                <div style={{
                        backgroundImage: "url('/home/epitkezes.webp')",
                        height: "max-content",
                        backgroundPosition: "center",
                        backgroundRepeat: "repeat",
                        backgroundSize: "cover",
                        backgroundAttachment: "fixed",
                    }}>
                    <MDBMask className="flex-center flex-column text-white text-center" overlay="black-strong">
                            <div className="d-flex justify-content-around px-4">
                                <ProjektCard imgs="galleryImages1" img="/kissSzilard/VRP_0811.jpg" title="KISS SZILÁRD SPORTCSARNOK"/>
                                <ProjektCard imgs="galleryImages2" img="/szivacs/VRP_0975.jpg" title="Szivacskézilabda csarnok"/>
                            </div>
                        <div className="d-flex justify-content-around py-4 px-4" style={{width: "100%"}}>
                            <ProjektCard img="szaszbereg/unnamed.jpg" title="Szászberek Sportcsarnok"/>
                            <ProjektCard imgs="galleryImages3" img="/lipot/IMG_1383.jpg" title="Lipót sportcsarnok"/>
                        </div>
                        <div className="d-flex justify-content-around px-4 py-5">
                            <ProjektCard img="/home/epitkezesCrop.png" title="Siófoki Jubileum tér"/>
                            <ProjektCard img="/home/epitkezesCrop.png" title="Eifel projekt"/>
                        </div>
                        <div className="d-flex justify-content-around py-4 px-4" style={{width: "100%"}}>
                            <ProjektCard img="tenisz/tenisz1.jpg" title="Nemzeti Tenisz Edzőközpont pályafejlesztés"/>
                        </div>
                    </MDBMask>
                </div>
            </MDBMask>

        );
    }
}
export class LiveProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
        return (
            <MDBMask>
                <div style={{
                    backgroundImage: "url('/home/epitkezes.webp')",
                    height: "max-content",
                    backgroundPosition: "center",
                    backgroundRepeat: "repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}>
                    <MDBMask className="flex-center flex-column text-white text-center" overlay="black-strong">
                        <div className="d-flex justify-content-around px-4 py-5">
                            <ProjektCard imgs="galleryImages4" img="/lenti/lenti-kezilabdacsarnok.jpg" title="Lenti Kézilabda Munkacsarnok"/>
                            <ProjektCard img="/home/epitkezesCrop.png" title="Vecsés tanuszoda"/>
                        </div>
                        <div className="d-flex justify-content-around px-4 py-5">
                            <ProjektCard img="/home/epitkezesCrop.png" title="Vecsés Kézilabda Munkacsarnok"/>
                            <ProjektCard img="/home/epitkezesCrop.png" title="Hévíz Kézilabda Munkacsarnok"/>
                        </div>
                        <div className="d-flex justify-content-around px-4 py-5">
                            <ProjektCard img="/home/epitkezesCrop.png" title="Komló Kézilabda Munkacsarnok"/>
                            <ProjektCard img="/home/epitkezesCrop.png" title="Paks judó csarnok"/>
                        </div>
                        <div className="d-flex justify-content-around px-4 py-5">
                            <ProjektCard img="/home/epitkezesCrop.png" title="Kisbér Tanuszoda"/>
                            <ProjektCard img="/home/epitkezesCrop.png" title="Siófok kajak-kenu Edzőközpont"/>
                        </div>
                    </MDBMask>
                </div>
            </MDBMask>

        );
    }
}


