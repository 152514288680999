import React from "react";
import {MDBCol, MDBContainer, MDBRow, MDBFooter} from "mdbreact";

import Logo from "../peszter_edited.png";
import Ecm4 from "../ecm400.png";
import Ecm9 from "../ecm900.png";
import Ecm45 from "../ecm4500.png";


const FooterPage = () => {
    return (
        <section className="page-footer">
        <MDBFooter color="special-color-dark" className="font-small pt-4 mt-0 ">
            <MDBContainer fluid className="text-center">
                <MDBRow>
                    <MDBCol md="4">
                        <div>
                            <img src={Logo} alt="filter applied"
                                 style={{filter: "invert(100%)", webkitfilter: "invert(100%)",   width: "50%",
                                     height: "auto"}}/>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="col-md-3 px-0">
                                <img src={Ecm4} alt="filter applied" style={{width: "50%", height: "auto"}}/>
                            </div>
                            <div className="col-md-3 px-0">
                                <img src={Ecm9} alt="filter applied" style={{width: "50%", height: "auto"}}/>
                            </div>
                            <div className="col-md-3 px-0">
                                <img src={Ecm45} style={{width: "50%", height: "auto"}}/>
                            </div>
                        </div>
                    </MDBCol>
                    <MDBCol md="4" className="text-left align-items-center  ">
                       <div>
                        <h5 className="title">
                            Adószám: 11788821-2-07</h5>

                            <h5> Kivitelező nyilvántartási szám: 34A26686</h5>

                            <h5> Email: titkarsag@peszter.hu</h5>

                            <h5> 8000 Székesfehérvár,</h5>

                            <h5> Zsurló utca 15.</h5>
                       </div>
                        </MDBCol>
                        <MDBCol md="4 py-0">
                            <iframe  style={{maxWidth: "300px", maxHeight: "200px"}}  width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0"
                                    marginWidth="0" id="gmap_canvas"
                                    src="https://maps.google.com/maps?width=395&amp;height=294&amp;hl=en&amp;q=Palotai%20utca%204%20Szekesfeherv%C3%A1r+(Peszter)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
                <div className="footer-copyright text-center py-3">
                    <MDBContainer fluid>
                        &copy; {new Date().getFullYear()} Copyright: <a
                        href="https://www.mdbootstrap.com">Peszter Kft. - minden jog fenntartva</a>
                    </MDBContainer>
                </div>
            </MDBFooter>
        </section>
            );

}

export default FooterPage;