import React from "react";
import {MDBContainer, MDBRow, MDBCol, MDBIframe, MDBBtn, MDBIcon, MDBModalFooter, MDBModal} from "mdbreact";
import Lightbox from "react-image-lightbox";
import "./LightBox.css";
import {MDBModalBody} from "mdb-react-ui-kit";


class GalleryImages1 extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: [
            '/kissSzilard/VRP_0616.jpg',
            '/kissSzilard/VRP_0626.jpg',
            '/kissSzilard/VRP_0639.jpg',
            '/kissSzilard/VRP_0679.jpg',
            '/kissSzilard/VRP_0694.jpg',
            '/kissSzilard/VRP_0709.jpg',
            '/kissSzilard/VRP_0720.jpg',
            '/kissSzilard/VRP_0730.jpg',
            '/kissSzilard/VRP_0734.jpg',
            '/kissSzilard/VRP_0736.jpg',
            '/kissSzilard/VRP_0758.jpg',
            '/kissSzilard/VRP_0777.jpg',
            '/kissSzilard/VRP_0785 (1).jpg',
            '/kissSzilard/VRP_0790.jpg',
            '/kissSzilard/VRP_0811.jpg',
            '/kissSzilard/VRP_0847.jpg',
        ]
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Kiss Szilárd</h2>
                <h2 className="font-weight-bold text-center text-light">Sport csarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class GalleryImages2 extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: [
            '/szivacs/IMG_20170425_125246.jpg',
            '/szivacs/IMG_20170425_125303.jpg',
            '/szivacs/VRP_0969.jpg',
            '/szivacs/VRP_0975.jpg',
            '/szivacs/VRP_0979.jpg',
            '/szivacs/VRP_0986.jpg',
            '/szivacs/VRP_0988.jpg',
            '/szivacs/VRP_0997.jpg',
        ]
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">SzivacsKézilabda</h2>
                <h2 className="font-weight-bold text-center text-light">Csarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2016. Április - 2017.Április</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class GalleryImages3 extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: [
            '/lipot/IMG_1315.jpg',
            '/lipot/IMG_1323.jpg',
            '/lipot/IMG_1326.jpg',
            '/lipot/IMG_1328.jpg',
            '/lipot/IMG_1329.jpg',
            '/lipot/IMG_1338.jpg',
            '/lipot/IMG_1344.jpg',
            '/lipot/IMG_1350.jpg',
            '/lipot/IMG_1359.jpg',
            '/lipot/IMG_1383.jpg',
            '/lipot/IMG_1387.jpg',
        ]
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Lipót</h2>
                <h2 className="font-weight-bold text-center text-light">Sportcsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2016. Március - 2017. Április</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class GalleryImages4 extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        modal11: false,
        images: [
            '/lenti/lenti-kezilabdacsarnok.jpg',
            '/lenti/kezilabdamunkacsarnok.jpg',
            '/lenti/unnamed (1).jpg',
            '/lenti/unnamed (2).jpg',
            '/lenti/unnamed (3).jpg',
            "/lenti/kep0.jpg",
            "/lenti/kep1.jpg",
            "/lenti/kep2.jpg",
            "/lenti/kep3.jpg",
            "/lenti/kep4.jpg",
            "/lenti/kep5.jpg",
            "/lenti/kep6.jpg",
            "/lenti/kep7.jpg",
            "/lenti/kep8.jpg",
            "/lenti/kep9.jpg",
            "/lenti/kep10.jpg",
            "/lenti/kep11.jpg",
            "/lenti/kep12.jpg",
            "/lenti/kep13.jpg",
            "/lenti/kep14.jpg",
            "/lenti/kep15.jpg",
            "/lenti/kep16.jpg",
            "/lenti/kep17.jpg",
            "/lenti/kep18.jpg",
            "/lenti/kep19.jpg",
            "/lenti/kep20.jpg",
            "/lenti/kep21.jpg",
            "/lenti/kep22.jpg",
            "/lenti/kep23.jpg",
            "/lenti/kep24.jpg",
            "/lenti/kep25.jpg",
            "/lenti/kep26.jpg",
            "/lenti/kep27.jpg",
            "/lenti/kep28.jpg",
            'http://admin:Root1234@lenti.ddns.net:8001/ISAPI/Streaming/channels/102/picture',
        ]
    }
    toggle = nr => () => {
        let modalNumber = "modal" + nr;
        this.setState({
            ...this.state,
            [modalNumber]: !this.state[modalNumber]
        });
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} style={{width: "100%", height: "40vh"}} alt="Gallery" className="img-fluid"
                             onClick={() =>
                                 this.setState({photoIndex: privateKey, isOpen: true})
                             }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Lenti</h2>
                <h2 className="font-weight-bold text-center text-light">Kézilabdacsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-3 text-center text-light">2020. Március - 2021.Július</h2>
                <MDBContainer className="flex-center mb-3">
                    <div className="btn btn-danger" onClick={this.toggle(11)}>Pillanat Kép a munkálatókról</div>
                    <MDBModal size="lg" isOpen={this.state.modal11} toggle={this.toggle(11)}>
                        <MDBModalBody className="mb-0 p-0">
                            <MDBIframe id="inlineFrameExample"
                                title="Inline Frame Example" src="http://admin:Root1234@lenti.ddns.net:8001/ISAPI/Streaming/channels/101/picture" />
                        </MDBModalBody>
                        <MDBModalFooter className="justify-content-center">
                            <MDBBtn color="primary" outline rounded size="md" className="ml-4" onClick={this.toggle(11)}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                </MDBContainer>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}


class GalleryImages5 extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Kiss Szilárd</h2>
                <h2 className="font-weight-bold text-center text-light">Sport csarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class VecsesTan extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Vecsés</h2>
                <h2 className="font-weight-bold text-center text-light">Tanuszoda</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class VecsesKezi extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Vecsés</h2>
                <h2 className="font-weight-bold text-center text-light">Kézilabda Munkacsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class KisberTanuszoda extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Kisbér</h2>
                <h2 className="font-weight-bold text-center text-light">Tanuszoda</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}
class HevizKezi extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Hévíz</h2>
                <h2 className="font-weight-bold text-center text-light">Kézilabda Munkacsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class KomloKezi extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Komló</h2>
                <h2 className="font-weight-bold text-center text-light">Kézilabda Munkacsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class PaksJudo extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Paks</h2>
                <h2 className="font-weight-bold text-center text-light">Judó Sportcsarnok</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class SiofokKajakKenu extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Siófok</h2>
                <h2 className="font-weight-bold text-center text-light">Kajak-Kenu edzőközpont</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class JubileumTer extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Siófok</h2>
                <h2 className="font-weight-bold text-center text-light">Jubileum Tér</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}

class EifelProjekt extends React.Component {
    state = {
        photoIndex: 0,
        isOpen: false,
        images: []
    }

    renderImages = () => {
        let photoIndex = -1;
        const {images} = this.state;

        return images.map(imageSrc => {
            photoIndex++;
            const privateKey = photoIndex;
            return (
                <MDBCol md="4" key={photoIndex}>
                    <figure>
                        <img src={imageSrc} alt="Gallery" className="img-fluid" onClick={() =>
                            this.setState({photoIndex: privateKey, isOpen: true})
                        }
                        />
                    </figure>
                </MDBCol>
            );
        })
    }

    render() {
        const {photoIndex, isOpen, images} = this.state;
        return (
            <MDBContainer className="pt-5" style={{maxWidth: "100%",}}>
                <h2 className="font-weight-bold pt-5 mb-1 mt-5  text-center text-light">Eifel</h2>
                <h2 className="font-weight-bold text-center text-light">Projekt</h2>
                <h2 className="font-weight-bold  mt-1 mb-5 text-center text-light">2017. Óktober - 2018.Márciús</h2>
                <div className="mdb-lightbox">
                    <MDBRow>
                        {this.renderImages(this.state)}
                    </MDBRow>
                </div>
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                        imageTitle={photoIndex + 1 + "/" + images.length}
                        onCloseRequest={() => this.setState({isOpen: false})}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                            })
                        }
                    />
                )}
            </MDBContainer>
        );
    }
}


export {GalleryImages1, GalleryImages2, GalleryImages3, GalleryImages4, GalleryImages5, VecsesTan, VecsesKezi, KisberTanuszoda, HevizKezi, KomloKezi, PaksJudo, SiofokKajakKenu, JubileumTer, EifelProjekt}
