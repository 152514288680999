import React from 'react';
import {MDBMask, MDBView} from "mdbreact";


export default function HomePage() {
    return (
        <MDBMask>
            <MDBView id="picture">
                <div style={{
                    backgroundImage: "url('/home/epitkezes.webp')",
                    height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundAttachment: "fixed",
                }}>
                    <MDBMask className="flex-center flex-column text-white text-center " overlay="black-strong">
                        <h1 className="font-weight-bold" style={{fontSize: "400%"}}>A JÖVŐT</h1>
                        <h1 className="font-weight-bold" style={{fontSize: "400%"}}>ÉPÍTJÜK!</h1>
                        <a className="nav-link" href="#about" style={{transform: "scaleY(-1)"}}>
                            <svg preserveAspectRatio="none" data-bbox="20.5 40.5 159 119" viewBox="20.5 40.5 159 119"
                                 height="100" width="100" xmlns="http://www.w3.org/2000/svg" data-type="shape"
                                 role="img"
                                 fill="white">
                                <g>
                                    <path
                                        d="M100 74.5l-5.678 5.667-56.786 56.666 5.678 5.667L100 85.578l56.786 56.922 5.678-5.667-56.786-56.666L100 74.5z"/>
                                    <path
                                        d="M100 108.5l-5.678 5.667-39.75 39.667 5.678 5.666L100 119.833l39.749 39.667 5.679-5.666-39.75-39.667L100 108.5z"/>
                                    <path
                                        d="M105.678 46.166L100 40.5l-5.678 5.666L20.5 119.833l5.678 5.667L100 51.877l73.822 73.623 5.678-5.667-73.822-73.667z"/>
                                </g>
                            </svg>
                        </a>
                    </MDBMask>
                </div>
            </MDBView>
            <section id="about" style={{
                backgroundImage: "url('/home/siofokcsarnok.webp')",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundAttachment: "fixed"
            }}>
                <MDBMask className="d-flex flex-column mx-0 my-0 px-lg-5 mt-0 mb-0 w-50" overlay="black-strong">
                    <h1 className="text-left font-weight-bold text-white py-5 ">RÓLUNK</h1>
                    <h5 className="text-left  text-white pb-5 w-50">
                        Építőipari vállalatként számos beruházást sikeresen megvalósítottunk, ezek teljeskörű
                        kivitelezését cégünk biztosította. Célunk, hogy az általunk létrehozott épületek
                        maradéktalanul szolgálják a jövő generációját. Nem csak építünk, hanem alkotunk is. A
                        kivitelezések során törekszünk arra, hogy a Megrendelőink igényeinek maximálisan
                        megfeleljünk, emellett költséghatékonyan-kiváló minőségben végezzük munkánkat, hogy az
                        általunk átadott épület ne csak szép, hanem gazdaságosan fenntartható is legyen.
                    </h5>
                </MDBMask>
            </section>
            <section id="features" className="text-white">
                <div className="d-flex justify-content-between">
                    <div className="col text-center px-0" style={{
                        backgroundImage: 'url(/home/VRP_0785.jpg)',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundAttachment: "fixed"
                    }}>
                        <MDBMask overlay="black-strong" style={{gridAutoFlow: "row"}}>
                            <div className="d-flex flex-column justify-content-between">
                                <div style={{height:" 10%"}}  className="pt-5 pb-lg-5">
                                    <h2>KIVITELEZÉSI</h2>
                                    <h2>MUNKÁK </h2>
                                </div>
                                <div style={{height:" 10%"}} className="pb-lg-5 ">
                                    <h3>GENERÁLKIVITELEZÉSI</h3>
                                    <h3>MUNKÁK</h3>
                                </div>
                                <div style={{height:" 10%"}} className="pb-lg-5">
                                    <h3>LAKÓ ÉS NEM LAKÓ</h3>
                                    <h3>ÉPÜLETEK KIVITELEZÉSE</h3>
                                </div>
                                <div style={{height:" 10% "}} className="pb-lg-5">
                                    <h3>SPORTPÁLYÁK ÉPÍTÉSE</h3>
                                </div>
                                <div style={{ height: "10%"}} className="pb-lg-5">
                                    <h3>ÚT-PARKOLÓ ÉS</h3>
                                    <h3>KÖZMŰÉPÍTÉSI MUNKÁK</h3>
                                </div>
                            </div>
                        </MDBMask>
                    </div>
                    <div className="col text-center px-0" style={{
                        backgroundImage: 'url(/home/IMG_1318.jpg)',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundAttachment: "fixed"
                    }}>
                        <MDBMask overlay="black-strong"  style={{gridAutoFlow: "row"}}>
                            <div className="d-flex  justify-content-between  flex-column ">
                                <div  style={{height: "10%"}}  className="pt-5 pb-lg-5">
                                    <h2>MÉRNÖKI</h2>
                                    <h2>TEVÉKENYSÉG</h2>
                                </div>
                                <div  style={{ height: "10%"}} className="pb-lg-5">
                                    <h3>FELELŐS MŰSZAKI</h3>
                                    <h3>VEZETÉS</h3>
                                </div>
                                <div  style={{ height: "10%"}} className="pb-lg-5">
                                    <h3>MŰSZAKI</h3><h3> ELLENŐRZÉS</h3>
                                </div>
                                <div  style={{ height: "10%"}} className="pb-lg-5" >
                                    <h3 >MŰSZAKI </h3>
                                    <h3>OPTIMALIZÁLÁS</h3>
                                </div>
                                <div style={{ height: "10%"}} className="pb-lg-5">
                                    <h3>TERVELLENŐRZÉS</h3>
                                </div>
                            </div>
                        </MDBMask>
                    </div>
                </div>
            </section>
        </MDBMask>
    );
}