import React from 'react';
import FooterPage from "./Components/FooterPage.jsx";
import HomePage from "./Components/HomePage.jsx";
import Header from "./Components/Header.jsx";
import {DoneProjects, LiveProject} from "./Components/DoneProjects.jsx";
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {MDBMask} from "mdbreact";
import {
    GalleryImages1,
    GalleryImages2,
    GalleryImages3,
    GalleryImages4,
    GalleryImages5
} from "./Components/GalleryProjects.jsx";

function App() {
    return (
        <div>
            <Header/>
            <div className="App" style={{
                backgroundImage: "url('/home/epitkezes.webp')",
                height: "max-content",
                backgroundPosition: "center",
                backgroundRepeat: "repeat",
                backgroundSize: "cover",
                backgroundAttachment: "fixed",
            }}>
                <MDBMask overlay="black-strong">
                    <BrowserRouter>
                        <Switch>
                            <Route exact path="/" component={HomePage}/>
                            <Route path="/liveProjects" component={LiveProject}/>
                            <Route path="/doneProjects" component={DoneProjects}/>
                            <Route path="/galleryImages1" component={GalleryImages1}/>
                            <Route path="/galleryImages2" component={GalleryImages2}/>
                            <Route path="/galleryImages3" component={GalleryImages3}/>
                            <Route path="/galleryImages4" component={GalleryImages4}/>
                            <Route path="/galleryImages5" component={GalleryImages5}/>
                        </Switch>
                    </BrowserRouter>
                </MDBMask>
            </div>
            <FooterPage/>
        </div>
    );
}


export default App;
