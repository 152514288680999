import React from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarToggler,
    MDBCollapse,
    MDBNavItem, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBNavLink,
} from 'mdbreact';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import Logo from "../peszter_edited.png";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    render() {
        return (
            <header>
                <Router>
                    <MDBNavbar color="bg-dark" fixed="top" dark expand="md" scrolling transparent>
                        <MDBNavbarBrand>
                            <a className="nav-link" href="/">
                            <div>
                                <img src={Logo} alt="filter applied"
                                     style={{
                                         filter: "invert(100%)", webkitfilter: "invert(100%)", width: "100%",
                                         height: "auto"
                                     }}/>
                            </div>
                            </a>
                        </MDBNavbarBrand>
                        {!this.state.isWideEnough && <MDBNavbarToggler onClick={this.onClick}/>}
                        <MDBCollapse isOpen={this.state.collapse} navbar>
                            <MDBNavbarNav right>
                                <MDBNavItem>
                                    <a  className="nav-link" href="/">Home</a>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <a className="nav-link" href="/#about">Rólunk</a>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <Link to="../home/adatkezelesiszabalyzat.pdf" target="_blank" download className="nav-link">Adatvédelem</Link>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <a className="nav-link" href="/#features">Szolgáltatásaink</a>
                                </MDBNavItem>
                                <MDBNavItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle nav caret>
                                            <span className="mr-2">Projektek</span>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem href="doneProjects">Befejezett</MDBDropdownItem>
                                            <MDBDropdownItem href="liveProjects">Aktuális</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                </Router>
            </header>
        );
    }
}

export default Header;
